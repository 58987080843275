"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable */
var __default__ = {
    data: function () {
        return {
            calcMax: Math.max(this.max, this.value),
        };
    },
    props: {
        value: Number,
        title: String,
        showValue: {
            type: Boolean,
            default: false,
        },
        max: {
            default: 5,
        },
    },
    methods: {
        isUrl: function (txt) {
            var r = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
            return txt.match(r);
        },
        isEmail: function (txt) {
            var r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return txt.match(r);
        },
    }
};
var vue_1 = require("vue");
var __injectCSSVars__ = function () {
    (0, vue_1.useCssVars)(function (_ctx) { return ({
        "5e0bc808": (100 / _ctx.calcMax * _ctx.value + '%')
    }); });
};
var __setup__ = __default__.setup;
__default__.setup = __setup__
    ? function (props, ctx) { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
exports.default = __default__;
