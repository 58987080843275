"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-79c12360"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = ["id"];
var _hoisted_2 = { key: 0 };
var _hoisted_3 = { class: "horizontal" };
var _hoisted_4 = { key: 0 };
var _hoisted_5 = ["href"];
var _hoisted_6 = { key: 2 };
var _hoisted_7 = { key: 3 };
var _hoisted_8 = {
    key: 1,
    class: "desc"
};
var _hoisted_9 = { key: 2 };
/* eslint-disable */
var __default__ = {
    props: {
        title: String,
        desc: {
            required: false,
            type: String,
        },
        subt: {
            required: false,
            type: String,
        },
        footer: {
            required: false,
            type: String,
        },
        array: {
            default: false,
            required: false,
            type: Boolean,
        },
        range: {
            default: false,
            required: false,
            type: Boolean,
        },
        skiptitle: {
            default: false,
            required: false,
            type: Boolean,
        },
        showValue: {
            default: false,
            required: false,
            type: Boolean,
        },
        date: {
            required: false,
            type: String,
        },
    },
    methods: {
        isUrl: function (txt) {
            var r = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
            return txt.match(r);
        },
        isEmail: function (txt) {
            var r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return txt.match(r);
        },
    },
    created: function () {
        // console.log(JSON.parse(JSON.stringify(this.$props)));
        console.log(this.$slots._);
    },
};
var tag_vue_1 = require("./tag.vue");
var range_vue_1 = require("./range.vue");
exports.default = (0, vue_1.defineComponent)(__assign(__assign({}, __default__), { __name: 'section-desc', setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                id: __props.title ? __props.title.replace(/\s/g, '').toLowerCase() : ''
            }, [
                (__props.title || __props.title == '')
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("h4", _hoisted_2, [
                        (__props.date)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(tag_vue_1.default, {
                                key: 0,
                                value: __props.date,
                                arrow: ""
                            }, null, 8, ["value"]))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(__props.title), 1)
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("h5", null, (0, vue_2.toDisplayString)(__props.subt) + " @ ", 513), [
                        [vue_2.vShow, __props.subt || __props.subt == '']
                    ]),
                    (__props.desc)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                            key: 0,
                            class: (0, vue_2.normalizeClass)(["desc", { array: __props.array }])
                        }, [
                            (!__props.array && !_ctx.isUrl(__props.desc))
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("p", _hoisted_4, (0, vue_2.toDisplayString)(__props.desc.replace("\\n", "<br />")), 1))
                                : (!__props.array && _ctx.isUrl(__props.desc))
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                        key: 1,
                                        href: _ctx.isEmail(__props.desc) ? 'mailto:' : '' + __props.desc,
                                        target: "__blank"
                                    }, (0, vue_2.toDisplayString)(__props.desc.replace('https://', '').replace('www.', '')), 9, _hoisted_5))
                                    : (__props.range)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_6, [
                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.desc.split(','), function (t) {
                                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(range_vue_1.default, {
                                                    key: t,
                                                    title: t.trim().split('=')[0],
                                                    value: parseFloat(t.trim().split('=')[1]),
                                                    showValue: __props.showValue
                                                }, null, 8, ["title", "value", "showValue"]));
                                            }), 128))
                                        ]))
                                        : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_7, [
                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.desc.split(','), function (t) {
                                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(tag_vue_1.default, {
                                                    skew: "",
                                                    value: t.trim()
                                                }, null, 8, ["value"]));
                                            }), 256))
                                        ]))
                        ], 2))
                        : (0, vue_2.createCommentVNode)("", true)
                ]),
                (_ctx.$slots._)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_8, [
                        (0, vue_2.renderSlot)(_ctx.$slots, "default")
                    ]))
                    : (0, vue_2.createCommentVNode)("", true),
                (__props.footer)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("h5", _hoisted_9, (0, vue_2.toDisplayString)(__props.footer), 1))
                    : (0, vue_2.createCommentVNode)("", true)
            ], 8, _hoisted_1));
        };
    } }));
